import React from "react";
import { NavLink } from "react-router-dom";
import { ReactComponent as Call } from "../../assets/BottomBar/call.svg";
import { ReactComponent as College } from "../../assets/BottomBar/college.svg";
import { ReactComponent as Home } from "../../assets/BottomBar/home.svg";
import { ReactComponent as Whatsapp } from "../../assets/BottomBar/whatsapp.svg";


const BottomNavigation = () => {

    const handleExpertHelp = () => {
        // WhatsApp number - replace with your actual number
        const whatsappNumber = "+916005130753";
        // Create WhatsApp URL
        const whatsappUrl = `https://wa.me/${whatsappNumber}`;
        // Open WhatsApp in a new tab
        window.open(whatsappUrl, "_blank");
      };
  return (
    <div className="fixed bottom-0 left-0 rounded-[10px_10px_0px_0px] border-[1px] border-[rgba(0,0,0,0.25)] w-full bg-white shadow-md z-50 rounded-t-2xl shadow-[1px_2px_11px_4px _rgba(0, 0, 0, 0.25)] lg:hidden">
      <div className="flex justify-around items-center py-2">
        {/* Home */}
        <NavLink
          to="/"
          className={({ isActive }) =>
            `flex flex-col items-center ${
              isActive ? "text-green-500" : "text-gray-500"
            }`
          }
        >
          <Home/>
          <span className="text-xs font-medium">Home</span>
        </NavLink>

        {/* Call */}
        <NavLink
          to="/colleges"
          className={({ isActive }) =>
            `flex flex-col items-center ${
              isActive ? "text-green-500" : "text-gray-500"
            }`
          }
        >
     <College/>
          <span className="text-xs font-medium">Colleges</span>
        </NavLink>

        {/* Chat */}
        <NavLink
        to="/"
         
          className={({ isActive }) =>
            `flex flex-col items-center ${
              isActive ? "text-green-500" : "text-gray-500"
            }`
          }
        >
        <div  onClick={handleExpertHelp}>

    
        <Whatsapp/>

            </div>
          <span className="text-xs font-medium">Chat</span>
        </NavLink>

        {/* AI Search */}
        <a
  href={`tel:${6005130753}`}
  style={{textAlign:"center"}}
  
 
>


  
<Call />

<span className="text-xs font-medium text-center ">Call</span>
</a>
        
      </div>
    </div>
  );
};

export default BottomNavigation;
