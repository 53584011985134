import axios from "axios";
import { Parser } from "htmlparser2";
import throttle from 'lodash-es/throttle';
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Base_URL } from "../apiConfig";
import Tula from "../assets/landing/Tula.jpg";
import "../styles/college.css";
import "./Scrollbar.css";
const CareerLayout = () => {
  const { career, item } = useParams();
  const location = useLocation();
  const [streamData, setStreamData] = useState(null);
  const [subCareerData, setSubCareerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedSection, setSelectedSection] = useState("About");
  const [displayName, setDisplayName] = useState("");
  const streams = location.state?.streams || [];
  const [originalTop, setOriginalTop] = useState(null); // Initially null until loaded
  const [isSticky, setIsSticky] = useState(false);
  const [preview, setPreview] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(0); // Add state to track header height
  const mainSectionRef = useRef(null);
  const subheaderRef = useRef(null);
  useEffect(() => {
    const updateHeaderHeight = () => {
      const header = document.querySelector("header"); // Adjust selector to match your header
      if (header) {
        setHeaderHeight(header.offsetHeight); // Set header height
      }
    };

    updateHeaderHeight(); // Initial call to set header height
    window.addEventListener("resize", updateHeaderHeight); // Update on resize

    return () => {
      window.removeEventListener("resize", updateHeaderHeight);
    };
  }, []);
  useEffect(() => {
    const updateOriginalTop = () => {
      if (subheaderRef.current) {
        setOriginalTop(
          subheaderRef.current.getBoundingClientRect().top + window.scrollY
        );
      }
    };

    // Set original top position after component loads
    setTimeout(updateOriginalTop, 100);

    // Define the scroll handler
    const handleScroll = () => {
      if (
        originalTop !== null &&
        window.scrollY >= originalTop - headerHeight
      ) {
        setIsSticky(true);
      } else if (
        originalTop !== null &&
        window.scrollY < originalTop - headerHeight
      ) {
        setIsSticky(false);
      }
    };

    // Throttle the scroll handler
    const throttledScrollHandler = throttle(handleScroll, 100);

    // Attach event listeners
    window.addEventListener("scroll", throttledScrollHandler);
    window.addEventListener("resize", updateOriginalTop);

    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
      window.removeEventListener("resize", updateOriginalTop);
    };
  }, [originalTop, headerHeight]);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, [location]);

  // Fetch data when component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Try to fetch from streams first
        const streamResponse = await axios.get(`${Base_URL}/get/streams`);
        const streamMatch = streamResponse.data.find(
          (s) => s.id === parseInt(item)
        );

        if (streamMatch) {
          setDisplayName(streamMatch.stream_name);
          // Fetch stream details
          const streamDetailsResponse = await axios.get(
            `${Base_URL}/get/streams_details`
          );
          const streamDetails = streamDetailsResponse.data.find(
            (detail) => detail.streams_id === parseInt(item)
          );
          setStreamData(streamDetails);
        } else {
          // If not found in streams, try SubCareerMaster
          const subCareerResponse = await axios.get(
            `${Base_URL}/get/SubCareerMaster`
          );
          const subCareerMatch = subCareerResponse.data.find(
            (sc) => sc.id === parseInt(item)
          );

          if (subCareerMatch) {
            setDisplayName(subCareerMatch.name);
            // Fetch SubCareerMapping data
            const mappingResponse = await axios.get(
              `${Base_URL}/get/SubCareerMapping`
            );
            const matchedMapping = mappingResponse.data.find(
              (mapping) => mapping.sub_career_master_id === parseInt(item)
            );
            setSubCareerData(matchedMapping);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [item]);

  // HTML Parser function
  const parseHtml = (html) => {
    if (!html) return "";

    let result = "";
    const parser = new Parser({
      ontext: (text) => {
        result += text;
      },
      onopentag: (name, attributes) => {
        if (name === "br" || name === "p") {
          result += "\n";
        }
      },
    });

    parser.write(html);
    parser.end();
    return result;
  };

  // Render section with parsed HTML
  const renderSection = () => {
    if (streamData) {
      // Render stream data
      switch (selectedSection) {
        case "About":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(streamData.about)}
            </div>
          );
        case "Eligibility":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(streamData.eligibility)}
            </div>
          );
        case "Job Roles":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(streamData.job_roles)}
            </div>
          );
        case "Work Description":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(streamData.work_description)}
            </div>
          );
        case "Salary":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(streamData.salary)}
            </div>
          );
        case "Prep Books":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(streamData.prep_books)}
            </div>
          );
        default:
          return null;
      }
    } else if (subCareerData) {
      // Render sub career data
      switch (selectedSection) {
        case "About":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(subCareerData.about)}
            </div>
          );
        case "Eligibility":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(subCareerData.eligibility)}
            </div>
          );
        case "Job Roles":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(subCareerData.job_roles)}
            </div>
          );
        case "Work Description":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(subCareerData.work_description)}
            </div>
          );
        case "Salary":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(subCareerData.salary)}
            </div>
          );
        case "Prep Books":
          return (
            <div className='container mx-auto px-4 py-6 whitespace-pre-line'>
              {parseHtml(subCareerData.prep_books)}
            </div>
          );
        default:
          return null;
      }
    }
    return <p>No data available.</p>;
  };

  const sections = [
    "About",
    "Eligibility",
    "Job Roles",
    "Work Description",
    "Salary",
    "Prep Books",
  ];

  // Add this function to handle WhatsApp routing
  const handleExpertHelp = () => {
    // WhatsApp number - replace with your actual number
    const whatsappNumber = "6005130753";
    // Create WhatsApp URL
    const whatsappUrl = `https://wa.me/${whatsappNumber}`;
    // Open WhatsApp in a new tab
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      <div className=' py-2 mt-20 relative w-full overflow-hidden'>
        <img
          src={Tula}
          alt='Banner'
          className='w-full h-auto max-w-[90%] sm:max-w-[80%] md:max-w-[75%] lg:max-w-screen-lg object-cover mx-auto mt-10 rounded-[20px]'
          style={{
            maxHeight: "450px",
            boxShadow: "1px 2px 11px 0px rgba(0, 0, 0, 0.25)",
          }}
        />
      </div>
      <h1 className='text-center text-2xl md:text-4xl font-bold mt-6 mb-12'>
        {displayName}
      </h1>

      <div
        id='subheader'
        ref={subheaderRef}
        className={` subheader    top-[-3px] flex gap-5 p-5 text-base font-medium text-black college_sub_header   bg-white shadow-md md:justify-center`}
        style={{
          position: isSticky ? "fixed" : "static",
          top: isSticky ? "90px" : "auto",
          width: "100%",
        }}
      >
        {sections.map((section) => (
          <div
            key={section}
            className={`relative py-2 px-2 sm:px-4 cursor-pointer font-[500] ${
              selectedSection === section ? " college_sub_header_active" : ""
            } `}
            onClick={() => setSelectedSection(section)}
          >
            {section}
          </div>
        ))}
      </div>
      <div className='container mx-auto px-4 py-6' ref={mainSectionRef}>
        <div>{loading ? <p>Loading...</p> : renderSection()}</div>
      </div>
      <div
        className='flex flex-col md:flex-row h-[168px] items-center justify-center mt-8 mb-16 space-y-4 md:space-y-0 md:space-x-4'
        style={{
          background: "linear-gradient(270deg, #DBFDC3 16.5%, #FFFFFF 78%)",
          border: "1px solid #00000040",
        }}
      >
        <p className='text-center text-sm md:text-2xl font-[500] font-dm-sans md:mb-0'>
          Planning to choose {career} as your Career
        </p>
        <button
          className='w-[240px] h-[49px] bg-[#75E36B] text-white font-bold rounded-[20px] flex items-center justify-center'
          onClick={handleExpertHelp} // Add onClick handler
        >
          Get Expert Help
        </button>
      </div>
    </div>
  );
};

export default CareerLayout;
