import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CourseListService from "../../api/services/CourseListService";
import { fetchFilteredCourses } from "../../api/services/FilterService";
import MasterDataService from "../../api/services/MasterDataService";
import filter from "../../assets/landing/filter.png";
import nodata from "../../assets/landing/no_data.png";
import HatCircleLoader from "../../components/Loader/HatCircleLOader";

const AllCoursesList = () => {
  const [wishlist, setWishlist] = useState(new Set());
  const [courses, setCourses] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [modeSearch, setModeSearch] = useState("");
  const [durationSearch, setDurationSearch] = useState("");
  const [modeList, setModeList] = useState([]);
  const [loading,setIsLoading]=useState(false)
  const [durationList, setDurationList] = useState([]);
  const [selectedMode, setSelectedMode] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState([]);
  const [selectedFilters,setSelectedFilters]=useState([])
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);
  const [activeMobileFilter, setActiveMobileFilter] = useState("Mode");
  const [isSticky, setIsSticky] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 10); // Set sticky if scrolled past 85px
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // Fetch all filter options and courses on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const [modes, durations] = await Promise.all([
          MasterDataService.fetchModes(),
          MasterDataService.fetchDurations(),
        ]);

        setModeList(
          modes.map((item) => ({ id: item.id, name: item.mode_title }))
        );
        setDurationList(
          durations.map((item) => ({ id: item.id, name: item.duration_title }))
        );

        fetchAllCourses(); // Fetch all courses initially
      } catch (error) {
        console.error("Failed to fetch filter data:", error);
      }finally{
        setIsLoading(false)
      }
    };

    fetchData();
  }, []);

  // Fetch all courses
  const fetchAllCourses = async () => {
    try {
      const courses = await CourseListService.fetchCourses();
      setFilteredData(courses);
    } catch (error) {
      toast.error("Failed to fetch courses");
      console.error("Failed to fetch courses:", error);
    }
  };

  // Fetch filtered courses based on selected filters
  const fetchFilterCourses = async () => {
    try {
      // Combine selectedMode and selectedDuration into a single array
   

      const response = await fetchFilteredCourses(selectedFilters);
      
      setFilteredData(response);
    } catch (error) {
      console.error("Error fetching filtered courses:", error);
    }
  };

  // Apply filters whenever the selected filters change
  useEffect(() => {
    if (selectedFilters.length > 0) {
      fetchFilterCourses()
    } else {
      fetchAllCourses() // Fetch all exams if no filters are selected
    }
  }, [selectedFilters]);

 // Handle checkbox changes for filters
 const handleCheckboxChange = (filterName) => {
  setSelectedFilters((prevFilters) => {
    if (prevFilters.includes(filterName)) {
      // Remove the filter if it exists
      return prevFilters.filter((item) => item !== filterName);
    } else {
      // Add the filter if it doesn't exist
      return [...prevFilters, filterName];
    }
  });
};
const handleSearchChange = (e, filterType) => {
  const value = e.target.value;
  if (filterType === "Mode") {
    setModeSearch(value);
  } else if (filterType === "Duration") {
    setDurationSearch(value);
  }
};
const handleClearFilters = () => {
  setSelectedFilters([]);
};
const handleClearAllFilters = (filter) => {
  setSelectedFilters((prevFilters) => {
    const filterData = getFilterData(filter).map((item) => item.name);
    // Remove the selected filters related to the cleared filter type
    return prevFilters.filter((item) => !filterData.includes(item));
  });

  if (filter === "Mode") {
    setSelectedMode([]);
  } else if (filter === "Duration") {
    setSelectedDuration([]);
  }
};

const getFilteredData = (filterType) => {
  const filterData = getFilterData(filterType);
  const searchValue = filterType === "Mode" ? modeSearch : durationSearch;

  if (!searchValue) return filterData;

  return filterData.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );
};
  const getFilterData = (filter) => {
    switch (filter) {
      case "Mode":
        return modeList;
      case "Duration":
        return durationList;
      default:
        return [];
    }
  };

  const handleMobileFilterClick = () => {
    setIsMobileFilterOpen(true);
  };

  const handleCloseMobileFilter = () => {
    setIsMobileFilterOpen(false);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    if (value === "all") {
      navigate("/colleges");
    } else if (value === "featured") {
      navigate("/colleges/featured-colleges");
    }
  };

  const displayData = filteredData || courses;

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <HatCircleLoader />
      </div>
    );
  }

  return (
    <>
      <div className='container mx-auto px-4 py-6 mt-20 flex flex-col lg:flex-row gap-6'>
        <div className='w-full mt-8 hidden md:block lg:w-1/4 font-dm-sans space-y-6'>
        {["Mode", "Duration"].map((filter, idx) => (
  <div
    key={idx}
    className="p-4 border rounded-lg shadow-lg bg-[#FCFCFC]"
  >
    <div className="flex justify-between items-center mb-4">
      <h3 className="font-dm-sans text-sm font-bold">{filter}</h3>
      <span
        className="font-dm-sans text-[12px] text-[#808080] font-[700] cursor-pointer"
        onClick={() => handleClearAllFilters(filter)}
      >
        Clear All
      </span>
    </div>
    <input
      type="text"
      className="w-full h-10 p-2 border font-dm-sans text-[14px] rounded mb-4"
      placeholder={`Search ${filter}`}
      value={filter === "Mode" ? modeSearch : durationSearch}
      onChange={(e) => handleSearchChange(e, filter)}
    />
    <ul className="space-y-2">
      {getFilteredData(filter)?.map((item) => (
        <li
          key={item.id}
          className="flex items-center text-[14px] font-dm-sans font-medium"
        >
          <input
            type="checkbox"
            className="mr-2"
            checked={selectedFilters.includes(item.name)}
            onChange={() => handleCheckboxChange(item.name)}
          />
          <span>{item.name}</span>
        </li>
      ))}
    </ul>
  </div>
))}

        </div>

        <div className='w-full lg:w-3/4 flex flex-col gap-8 lg:pr-16 lg:pl-4'>
        <div className="flex justify-center mb-8">
          <h2 className="font-dm-sans text-2xl lg:text-3xl font-bold text-center">
           All Courses
          </h2>
        </div>

  <div className='flex flex-col items-center gap-8'>
    <div className='flex flex-col gap-8 w-full lg:max-w-6xl'>
      {displayData?.length === 0 ? (
        <div className='flex flex-col items-center justify-center h-[calc(100vh-200px)] w-full'>
          
          <img src={nodata} alt='No data' className='w-40 h-40 mb-4' />
          <p className="text-lg font-semibold text-gray-600">
              No Courses found.
            </p>
            <button onClick={handleClearFilters} className="text-blue-500">
              View All Courses
            </button>
        </div>
      ) : (
        displayData.map((course) => (
          <div
            key={course.id}
            className='relative border border-gray-300 rounded-lg shadow-lg p-5 flex flex-col gap-4'
          >
            {/* Title Section */}
            <div className='flex justify-between items-center'>
              <h3 className='font-bold text-xl text-cmsgreen'>
                {course.course_master_name || "N/A"}
              </h3>
            </div>

            {/* Statistics Section */}
            <div className='flex gap-4'>
              <div className='flex-1 border border-gray-300 rounded-lg p-3'>
                <p className='text-gray-500 text-sm'>Average Duration</p>
                <p className='font-bold text-lg text-black'>
                  {`${course.duration_title}` || "N/A"}
                </p>
              </div>
              <div className='flex-1 border border-gray-300 rounded-lg p-3'>
                <p className='text-gray-500 text-sm'>Average Fees</p>
                <p className='font-bold text-lg text-black'>
                  {course.avg_fee || "N/A"}
                </p>
              </div>
            </div>

            {/* Description Section */}
            <p className='text-gray-700'>{course.intro}</p>

            {/* View Details Button */}
            <div className='text-right'>
              <button
                className='text-cmsgreen border border-cmsgreen px-4 py-2 rounded-lg font-medium'
                onClick={() =>
                  navigate(`/courses/details/${course.course_master_name}`)
                }
              >
                View Details
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  </div>
</div>





          {/* Sticky Footer Division for Mobile */}
      <div
  className={`fixed flex justify-between items-center 
    left-0 w-full bg-[#DCFFC4] border-b border-[#DADADA] lg:hidden p-4 transition-all duration-300 ${
      isSticky ? "top-0" : "top-[85px]"
    }`}
>
  {/* Filter Icon Column */}
  <div
    className="flex bg-white rounded-[5rem] border p-2 items-center justify-center"
    onClick={handleMobileFilterClick}
  >
    <img src={filter} alt="Filter" className="w-6 h-6" />
  </div>

  {/* Radio Buttons for Filter Options */}
  <div className="flex items-center space-x-4">
    <div className="flex items-center">
      <input
        type="radio"
        id="allCourses"
        name="filterOption"
        value="all"
        checked
        // onChange={handleRadioChange}
        className="mr-2 text-cmsgreen"
      />
      <label
        htmlFor="allCourses"
        className="text-sm font-dm-sans text-[#929292]"
      >
        All Courses
      </label>
    </div>
  </div>
</div>
    

    {/* Mobile Filter Modal */}
    {isMobileFilterOpen && (
      <div className="fixed inset-0 z-[60] bg-white flex flex-col lg:hidden">
          <div className='flex items-center justify-between p-4 border-b border-[#DADADA]'>
            <button
              onClick={handleCloseMobileFilter}
              className='text-[#929292]'
            >
              &#10005;
            </button>
          </div>

        <div className="flex flex-grow overflow-y-auto ">
          <div className="w-1/3 bg-white border-r border-b border-t border-[#929292] overflow-hidden">
            <ul className="space-y-2 p-0 mt-5 text-[16px] font-[700] font-dm-sans text-[#000000]">
              {['Mode', 'Duration'].map((filter, index) => (
                <li
                  key={index}
                  onClick={() => setActiveMobileFilter(filter)}
                  className={`p-2 cursor-pointer w-full ${activeMobileFilter === filter ? 'bg-cmsgreen text-white ' : 'bg-white'}`}
                  style={{ height: '38px' }}
                >
                  {filter}
                </li>
              ))}
            </ul>
          </div>

          <div className="w-2/3 bg-white overflow-y-auto p-4 ml-4">
            <ul className="space-y-2 mt-5">
            {getFilterData(activeMobileFilter)?.map((item) => (
      <li key={item.id} className="flex items-center p-2 text-[14px] font-dm-sans font-[700]">
        <input
          type="checkbox"
          className="mr-2 text-cmsgreen"
          checked={selectedFilters.includes(item.name)}
          onChange={() => handleCheckboxChange(item.name)}
        />
        <span>{item.name}</span>
      </li>
    ))}
            </ul>
          </div>
        </div>

        {/* Clear and Apply Buttons */}
        <div className="fixed bottom-0 left-0 w-full bg-cmsgreen border border-[#929292] lg:hidden">
          <div className="flex items-center h-16">
            <div className="flex items-center justify-center w-1/2" onClick={() => handleClearAllFilters(activeMobileFilter)}>
              <p className="text-[14px] font-dm-sans font-[500] text-white">Clear All</p>
            </div>
            <div className="h-full border-l border-white"></div>
            <div className="flex items-center justify-center w-1/2" onClick={handleCloseMobileFilter}>
              <p className="text-[14px] font-dm-sans font-[500] text-white">Apply Filters</p>
            </div>
          </div>
        </div>
      </div>
    )}


      </div>
    </>
  );
};

export default AllCoursesList;
