import React, { useState } from 'react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { HiBriefcase, HiChevronRight, HiNewspaper, HiOutlineCurrencyDollar } from 'react-icons/hi';
import { LiaGraduationCapSolid, LiaUniversitySolid } from 'react-icons/lia';
import { LuBookMinus } from 'react-icons/lu';
import { MdOutlineDashboard } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import Hat from "../../../../assets/landing/Hat.png";
import './Sidebar.css'; // Import the custom CSS

const Sidebar = ({ isSidebarOpen }) => {
  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedMenuItem, setSelectedMenuItem] = useState(null); // Add selected state
  const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(null); // Add selected sub-menu state

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index); // Set selected menu item
    setSelectedSubMenuItem(null); // Reset sub-menu selection when a new menu is selected
  };

  const handleSubMenuItemClick = (subIndex) => {
    setSelectedSubMenuItem(subIndex); // Set selected sub-menu item
  };

  const menuItems = [
    { title: 'Admin', icon: MdOutlineDashboard, path: '/admin', items: [] },
    // {
    //   title: 'Leads Management',
    //   icon: MdLeaderboard,
    //   items: [
    //     { title: 'All Leads', path: '/admin/studentLeads/all' },
       
    //   ],
    // },
    
    {
      title: 'Master Tables',
      icon: LiaUniversitySolid,
      items: [
        { title: 'Add Stream', path: '/admin/universityMaster/addStream' },
        { title: 'Add Program', path: '/admin/universityMaster/addProgram' },
        { title: 'Add Courses', path: '/admin/universityMaster/addCourse' },
        { title: 'Add Sub Courses', path: '/admin/universityMaster/subCourse' },
        { title: 'Add Company', path: '/admin/universityMaster/company' },
        { title: 'Add Country', path: '/admin/universityMaster/addCountry' },
        { title: 'Add State', path: '/admin/universityMaster/addState' },
        { title: 'Add City', path: '/admin/universityMaster/addCity' },
        { title: 'All Master Data', path: '/admin/universityMaster/allMaster' },
      ],
    },
    {
      title: 'Courses Details',
      icon: LiaGraduationCapSolid,
      items: [
        { title: 'All Duration ', path: '/admin/courseMasterDetails/allDuration' },
        { title: 'Course Duration Master', path: '/admin/courseMasterDetails/courseDuration' },
        { title: 'All Modes ', path: '/admin/courseMasterDetails/allModes' },
        { title: 'Course Modes Master' , path: '/admin/courseMasterDetails/courseMode' },
        { title: 'All Course Details ', path: '/admin/courseMasterDetails/all' },
        { title: 'Add Course Details ', path: '/admin/courseMasterDetails/add' },
          
          
       
      ],
    },
    {
      title: 'Exams',
      icon: LiaUniversitySolid,
      items: [
        { title: 'All Exams', path: '/admin/exams/allExams' },
        { title: 'Add Exams', path: '/admin/exams/addExams' },
        { title: 'Add Exam Stream', path: '/admin/exams/addExamstream' },
        { title: 'All Exam Level', path: '/admin/exams/allLevel' },
        { title: 'Add Exam Level', path: '/admin/exams/addExamLevel' },
        { title: 'All Exam Details', path: '/admin/exams/allSectionDetails' },
        { title: 'Add Exam Details', path: '/admin/exams/addSectionDetails' },
      ],
    },
    {
      title: 'News & Announcements',
      icon: HiNewspaper,
      items: [
        { title: 'Add DropDown-News', path: '/admin/news/addDropDownNews' },
        { title: 'All DropDown-News', path: '/admin/news/AlldropDownNews' },
      ],
    },
    {
      title: 'Website FAQs',
      icon: FaRegQuestionCircle,
      items: [
        { title: 'All Website Faq', path: '/admin/websiteFaqs/all' },
        { title: 'Add Website Faq', path: '/admin/websiteFaqs/add' },
      ],
    },
  
    {
      title: 'Careers',
      icon: FaRegQuestionCircle,
      items: [
        { title: 'Career ', path: '/admin/career/careerForm' },
        { title: 'All Career ', path: '/admin/career/AllCareerForm' },
        { title: 'Sub-Career', path: '/admin/career/subCareerForm' },
        { title: 'All Sub-Career ', path: '/admin/career/AllSubCareerForm' },
        { title: 'SubCareer-Mapping', path: '/admin/career/subCareerMapping' },
        { title: 'All SubCareer-Mapping ', path: '/admin/career/AllSubCareerMapping' },
        { title: 'Admin-Stream', path: '/admin/career/adminStream' },
        { title: 'All Admin-Stream ', path: '/admin/career/AllAdminStream' },
    
      ],
    },

    {
      title: 'University',
      icon: LiaUniversitySolid,
      items: [
        { title: 'All University', path: '/admin/university/all' },
        { title: 'Add University', path: '/admin/university/add' },
        { title: 'Add Gallery', path: '/admin/university/addGallery' },
        { title: 'All University City', path: '/admin/universityMaster/allUniversityCity' },
        { title: 'Add University City', path: '/admin/universityMaster/addUniversityCity' },
      ],
    },
   
    {
      title: 'Programs and Courses',
      icon: LiaGraduationCapSolid,
      items: [
        { title: 'All Programs', path: '/admin/programs/all' },
        { title: 'Add Programs', path: '/admin/programs/add' },
        { title: 'All Courses', path: '/admin/courses/all' },
        { title: 'Add Courses', path: '/admin/courses/add' },
        { title: 'All Sub Courses', path: '/admin/subCourses/all' },
        { title: 'Add Sub Courses', path: '/admin/subCourses/add' },
      ],
    },
    
    {
      title: 'Admission',
      icon: LuBookMinus,
      items: [
        { title: 'All Admissions', path: '/admin/admissions/all' },
        { title: 'Add Admissions', path: '/admin/admissions/add' },
      ],
    },
    {
      title: 'Scholarship',
      icon: HiOutlineCurrencyDollar,
      items: [
        { title: 'All Scholarship', path: '/admin/scholarship/all' },
        { title: 'Add Scholarship', path: '/admin/scholarship/add' },
      ],
    },
    {
      title: 'Placement',
      icon: HiBriefcase,
      items: [
        { title: 'All Placement', path: '/admin/placement/all' },
        { title: 'Add Placement', path: '/admin/placement/add' },
      ],
    },
    {
      title: 'University News',
      icon: HiNewspaper,
      items: [
        { title: 'All University News', path: '/admin/news/all' },
        { title: 'Add University News', path: '/admin/news/add' },
      ],
    },
    // {
    //   title: 'University Credentials',
    //   icon: LiaUniversitySolid,
    //   items: [
    //     { title: 'All Credentials', path: '/admin/university/allCredentials' },
    //     { title: 'Add Credentials', path: '/admin/university/addCredentials' },
    //   ],
    // },
    
    {
      title: 'University FAQs',
      icon: FaRegQuestionCircle,
      items: [
        { title: 'All Faq', path: '/admin/faqs/all' },
        { title: 'Add Faq', path: '/admin/faqs/add' },
      ],
    },
  
    
     
  ];

  return (
    <div
      className={`fixed top-0 left-0 h-screen z-10 text-gray-500 transition-all duration-300 ${
        isSidebarOpen ? 'w-64' : 'w-20'
      }`}
      style={{ transition: 'width 0.3s ease', fontFamily: 'Poppins, sans-serif' }}
    >
      <div className="flex items-center h-16 bg-cmsgreen">
      {/* <Link to="/">
            <Logo className={`w-24 h-24 md:w-32 md:h-32 lg:w-36 lg:h-36 ${isSidebarOpen ? '' : 'mx-auto'}`} />
          </Link> */}
          <Link to ="/">
          <img src={Hat} alt='Hat' className={`h-16 w-16 ${isSidebarOpen ? '' : 'mx-auto'}`} /></Link>
       
        {isSidebarOpen && <span className="text-white ml-2 text-xl">College Dwarka</span>}
      </div>
      <nav className={`flex-1 py-4 space-y-1 sidebar`}>
        {menuItems.map((item, index) => (
          <div key={index} className={`dashboard_sidebar_element ${isSidebarOpen ? 'px-0' : 'px-2'}`}>
            {/* Menu Item */}
            <div className="dashboard_sidebar_element">
              <div
                className={`flex items-center  px-4 py-2 text-[12.25px] font-lightbold ${
                  isSidebarOpen ? '' : 'rounded-md '
                } ${isSidebarOpen ? selectedMenuItem === index ? 'bg-[#f0f0fe] text-[#41B923] border-r-4 border-[#41B923]' : '' : selectedMenuItem === index ? 'bg-[#f0f0fe] text-[#41B923]' : ''
                } hover:${isSidebarOpen ? 'border-r-4 ' : 'border-r-0'} hover:border-[#41B923] font-medium menu cursor-pointer`}
                onClick={() => {
                  handleDropdownToggle(index);
                  handleMenuItemClick(index);
                }}
              >
                <item.icon className={`mr-2 hover:text-[#41B923] transition-all duration-300 ${isSidebarOpen ? 'text-xl' : 'text-2xl'} ${selectedMenuItem === index ? 'text-[#41B923]' : 'text-gray-500'}`} />
                {isSidebarOpen ? item.title : null}
                {isSidebarOpen && item.items.length > 0 && (
                  <HiChevronRight className={`ml-auto transition-transform duration-300 ${openDropdown === index ? 'rotate-90' : ''} ${selectedMenuItem === index ? 'text-[#41B923]' : 'text-gray-500'}`} />
                )}
              </div>

              {/* Submenu */}
              {isSidebarOpen && openDropdown === index && item.items.length > 0 && (
                <div className="pl-6 mt-1">
                  {item.items.map((subItem, subIndex) => (
                    <Link
                      key={subIndex}
                      to={subItem.path}
                      className={`flex items-center px-3 py-2 z-10 text-[12.25px] font-medium block ${location.pathname === subItem.path ? 'text-[#41B923]' : 'text-gray-500'} hover:text-[#41B923]`}
                      state={{ mainTitle: item.title, subTitle: subItem.title }}
                      onClick={() => handleSubMenuItemClick(subIndex)}
                    >
                      <HiChevronRight className={`mr-2 ${location.pathname === subItem.path ? 'text-[#41B923]' : 'text-gray-500'}`} />
                      {subItem.title}
                    </Link>
                  ))}
                </div>
              )}

              {/* Collapsed Submenu */}
              {!isSidebarOpen && item.items.length > 0 && (
                <div className="submenu">
                  <ul>
                    {item.items.map((subItem, subIndex) => (
                      <li key={subIndex}>
                        <Link
                          to={subItem.path}
                          className={`block rounded-md px-3 py-1 text-sm font-medium ${location.pathname === subItem.path ? 'text-[#41B923]' : 'text-[#737b8b]'} hover:text-[#41B923]`}
                          state={{ mainTitle: item.title, subTitle: subItem.title }}
                          onClick={() => handleSubMenuItemClick(subIndex)}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Sidebar;
