import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { FaCaretDown } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MasterDataService from "../../api/services/MasterDataService";
import { Base_URL, Img_URL } from "../../apiConfig";
import { ReactComponent as Courses } from "../../assets/header/Courses.svg";
import { ReactComponent as Dots } from "../../assets/header/Dots.svg";
import GiftBox from "../../assets/header/GiftBox.webm";
import { ReactComponent as Green_Search } from "../../assets/header/Green_Search.svg";
import { ReactComponent as Intelligence } from "../../assets/header/Intelligence.svg";
import { ReactComponent as Menu } from "../../assets/header/Menu.svg";
import { ReactComponent as Notification } from "../../assets/header/Notification.svg";
import { ReactComponent as Search } from "../../assets/header/Search.svg";
import { ReactComponent as User } from "../../assets/header/User.svg";
import { ReactComponent as Logo } from "../../assets/landing/Logo_Green.svg";
import Authentication from "../Authentication/Authentication";
import MoreDropdown from "./Dropdown/MoreDropdown";
import ProfileDropDown from "./Dropdown/ProfileDropDown";
import "./header.css";
import MobileMenu from "./MobileMenu";
import MobileSearch from "./MobileSearch";
import ReferEarnModal from "./Modal/ReferEarnModal";

const Navbar = () => {
  const [dotsDropdownVisible, setDotsDropdownVisible] = useState(false);
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);
  const [dropdownCloseTimeout, setDropdownCloseTimeout] = useState(null);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false); // Mobile menu state
  const [isReferEarnModalOpen, setReferEarnModalOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const [degrees, setDegrees] = useState([]); // Store degrees
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState(" College");
  const placeholders = [" College", " Exam", " Course"];
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [universityName, setUniversityName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileSearch, setMobileSearch] = useState(false); // Change to false initially

  const MIN_LENGTH = 3;

  const name = useSelector((state) => state.auth.name);
  const dispatch = useDispatch();
  const [authMode, setAuthMode] = useState("login"); // 'login' or 'signup'
  const [isOverlayOpen, setOverlayOpen] = useState(false);

  const location = useLocation(); // Get the current location

  const [recentSearches, setRecentSearches] = useState([]);
  const RECENT_SEARCH_LIMIT = 5;

  const useDebounce = (callback, delay) => {
    const timer = useRef(null);

    const debouncedFunction = (...args) => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };

    return debouncedFunction;
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setUniversityName(value);

    if (value.trim() && value.length >= MIN_LENGTH) {
      setDropdownVisible(true); // Show dropdown while typing
      debouncedFetchUniversities(value); // Fetch results
    } else {
      setDropdownVisible(false);
      setSearchResults([]); // Clear results if input is empty or too short
    }
  };

  const fetchUniversities = async (university_name) => {
    setIsLoading(true); // Set loading to true when fetch starts
    try {
      const response = await axios.get(`${Base_URL}/search/${university_name}`);
      if (response.data && Array.isArray(response.data)) {
        setSearchResults(response.data); // Set results
      } else {
        setSearchResults([]); // Clear results if no data
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
      setSearchResults([]); // Clear results in case of an error
    }
    setIsLoading(false); // Set loading to true when fetch starts
    // Set loading to false when fetch ends
  };

  const debouncedFetchUniversities = useDebounce(fetchUniversities, 500);

  const handleSearchSelect = async (college) => {
    try {
      const universityId = college.university_id;
      const universityNameSlug = encodeURIComponent(
        college.university_name.trim().toLowerCase().replace(/\s+/g, "-")
      );

      // Hide dropdown and set selected university
      setDropdownVisible(false);
      setUniversityName(college.university_name);
      setSelectedUniversity(college);
      saveToRecentSearches(college.university_name);
      // Fetch complete college details and pictures in parallel
      // Navigate to college page with complete details
      closeOverlay();
      setMobileSearch(false);

      navigate(`/college/${universityNameSlug}`, {});
    } catch (error) {
      console.error("Error fetching college details:", error);
    }
  };

  const openOverlay = () => {
    setOverlayOpen(true);

    document.body.classList.add("overflow-y-hidden");

    setSearchResults([]);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setUniversityName([]);
    setSearchResults([]);
  };

  useEffect(() => {
    if (isOverlayOpen) {
      document.body.style.overflow = "hidden !important";
    } else {
      document.body.style.overflow = "unset";
    }

    // Cleanup when component unmounts
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOverlayOpen]);

  const skeletonLoader = () => (
    <div className='space-y-4'>
      {Array.from({ length: 5 }).map((_, index) => (
        <div className='flex space-x-4 items-center '>
          <div key={index} className='space-x-4'>
            <Skeleton square={true} height={60} width={60} />
          </div>
          <div key={index} className='space-x-4' style={{ width: "100%" }}>
            <Skeleton height={36} width='100%' />
          </div>
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    // Close the mobile menu when the location changes
    setMobileMenuOpen(false);
    setSidebarOpen(false);
  }, [location]); // Dependency array includes location

  useEffect(() => {
    if (isSidebarOpen) {
      fetchDegrees(); // Fetch degrees when the sidebar is opened
    }
  }, [isSidebarOpen]);

  // Fetch degrees from MasterDataService
  const fetchDegrees = async () => {
    try {
      const response = await MasterDataService.fetchDegrees();
      setDegrees(response); // Store degrees in state
    } catch (error) {
      console.error("Error fetching degrees:", error);
    }
  };

  // Filter degrees based on search
  const filteredDegrees = degrees?.filter((degree) =>
    degree?.program_master_name
      ?.toLowerCase()
      .includes(searchTerm?.toLowerCase())
  );

  useEffect(() => {
    const searches = JSON.parse(localStorage.getItem("recentSearches")) || [];
    setRecentSearches(searches);
  }, []); // This runs once when the component mount

  const saveToRecentSearches = (searchTerm) => {
    let searches = JSON.parse(localStorage.getItem("recentSearches")) || [];
    // Remove duplicates and add new search to the top
    searches = [searchTerm, ...searches.filter((term) => term !== searchTerm)];
    // Limit the number of recent searches
    if (searches.length > RECENT_SEARCH_LIMIT) {
      searches.pop();
    }
    localStorage.setItem("recentSearches", JSON.stringify(searches));
    setRecentSearches(searches);
  };

  // Remove an item from recent searches
  const removeFromRecentSearches = (searchTerm) => {
    let searches = JSON.parse(localStorage.getItem("recentSearches")) || [];
    // Filter out the term to remove
    searches = searches.filter((term) => term !== searchTerm);
    localStorage.setItem("recentSearches", JSON.stringify(searches));
    setRecentSearches(searches);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      closeModal();
    }
  }, [token]);

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      index = (index + 1) % placeholders.length;
      setPlaceholder(placeholders[index]);
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, []);

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      zIndex: 1000,
    },
  };

  const handleMouseEnter = () => {
    if (dropdownCloseTimeout) {
      clearTimeout(dropdownCloseTimeout); // Prevent the dropdown from closing
      setDropdownCloseTimeout(null);
    }
    setDotsDropdownVisible(true);
    setProfileDropdownVisible(false);
  };

  const handleMouseLeave = () => {
    // Add a delay before closing the dropdown
    const timeout = setTimeout(() => {
      setDotsDropdownVisible(false);
    }, 300); // Adjust the delay as needed
    setDropdownCloseTimeout(timeout);
  };

  const handleNavigation = (program) => {
    const programSlug = program.toLowerCase().replace(/\s+/g, "-"); // Convert program name to slug
    setDotsDropdownVisible(false); // Close the dropdown instantly when navigating
    setSearchTerm("");
    navigate(`/filter/${programSlug}-colleges-in-india`); // Pass program name as state
  };

  const handleMouseEnterProfile = () => {
    if (dropdownCloseTimeout) {
      clearTimeout(dropdownCloseTimeout); // Prevent the dropdown from closing
      setDropdownCloseTimeout(null);
    }
    setProfileDropdownVisible(true);
    setDotsDropdownVisible(false);
  };

  const handleMouseLeaveProfile = () => {
    // Add a delay before closing the dropdown
    const timeout = setTimeout(() => {
      setProfileDropdownVisible(false);
    }, 300); // Adjust the delay as needed
    setDropdownCloseTimeout(timeout);
  };

  const handleSearchSubmit = async (e) => {
    if (e.key === "Enter") {
      try {
        let matchedCollege = null;

        // If a college is already selected from dropdown
        if (selectedUniversity) {
          matchedCollege = selectedUniversity;
        }
        // If user typed a name but didn't select from dropdown
        else if (universityName.trim()) {
          // First try to find an exact match in current search results
          matchedCollege = searchResults.find(
            (college) =>
              college.university_name.toLowerCase() ===
              universityName.toLowerCase()
          );

          // If no match in current results, make a new API call
          if (!matchedCollege) {
            const response = await axios.get(
              `${Base_URL}/search/${universityName}`
            );
            if (response.data && Array.isArray(response.data)) {
              matchedCollege = response.data.find(
                (college) =>
                  college.university_name.toLowerCase() ===
                  universityName.toLowerCase()
              );
            }
          }
        }

        if (matchedCollege) {
          const universityId = matchedCollege.university_id;
          const universityNameSlug = encodeURIComponent(
            matchedCollege.university_name
              .trim()
              .toLowerCase()
              .replace(/\s+/g, "-")
          );

          // Fetch complete college details and pictures before navigation
          const [detailsResponse, picturesResponse] = await Promise.all([
            axios.get(`${Base_URL}/universities/${universityId}`),
          ]);

          const completeCollegeDetails = {
            ...detailsResponse.data,
          };

          closeOverlay();
          // Navigate with complete details
          navigate(`/college/${universityNameSlug}`, {});
        } else {
          setUniversityName(""); // Clear input if no match found
        }
      } catch (error) {
        console.error("Error during search:", error);
        setUniversityName(""); // Clear input on error
      }
    }
  };

  const listHeightClass =
    searchResults.length > 0
      ? `h-[${Math.min(searchResults.length * 50, 300)}px]` // Dynamically set height, max 300px
      : "h-[150px]"; // Default height for empty state or loader

  const dummyPrograms = [
    { id: 1, program_master_name: "B.Tech" },
    { id: 2, program_master_name: "CSE" },
    { id: 3, program_master_name: "ECE" },
    { id: 4, program_master_name: "M.Tech" },
    { id: 5, program_master_name: "BBA" },
    { id: 6, program_master_name: "MBA" },
    { id: 7, program_master_name: "BCA" },
    { id: 8, program_master_name: "MCA" },
  ];

  const openReferEarnModal = () => {
    setReferEarnModalOpen(true);
  };

  const closeReferEarnModal = () => {
    setReferEarnModalOpen(false);
  };

  const openModal = (mode) => {
    setAuthMode(mode); // Set the authMode based on what user clicked (signup or login)
    setModalIsOpen(true); // Open the modal
  };

  const closeModal = () => setModalIsOpen(false); // Close modal handler
  return (
    <>
      <div
        className='hidden px-6 py-4 h-[100px] md:hidden w-full space-x-4 items-center justify-evenly lg:flex    '
        style={{ zIndex: 2 }}
      >
        <div className=''>
          <Link to='/'>
            <Logo className='h-[30px] xl:h-[40px]' />
          </Link>
        </div>

        <div className='h-[40px] w-[2px] bg-primary ' />

        <div className='flex justify-evenly self-center w-full '>
          {/* Vertical Line */}

          <div className='flex flex-col  items-center '>
            <div className='bg-primary px-[8px] py-[5px] w-[84px] h-[30px] xl:w-[120px]  xl:h-[30px]  justify-center relative top-1 self-center shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)] rounded-md text-white flex items-center space-x-2 shadow-md'>
              <Intelligence className='h-[15px] w-[15px]   ' />
              <span className='text-[8px] xl:text-xs '>AI Powered</span>
            </div>
            <div className='border w-[115px] xl:w-[180px] border-primary rounded-md px-[0px] py-[5px] flex justify-center items-center space-x-2 shadow-md '>
              <span className='text-primaryText text-[8px] xl:text-xs'>
                Search Goal & City
              </span>
              <FaCaretDown className='h-[16px] w-[16px] xl:w-[18px] xl:h-[18px] text-primaryText' />
            </div>
          </div>

          <div className='flex items-center self-end border border-gray-300 rounded-lg px-2  w-[216px] h-[30px] xl:w-[400px] xl:h-[40px] '>
            <Search className='w-[19.7px] h-[18px] ml-2' />
            <input
              placeholder={`Search for '${placeholder}'`}
              style={{ border: "none", padding: 10, fontSize: "12px" }}
              type='text'
              className=' outline-none h-[20px] w-[176px]'
              onClick={openOverlay}
              readOnly
            />
          </div>

          <div
            className='flex items-center self-end space-x-1 cursor-pointer'
            onClick={openReferEarnModal}
          >
            <video src={GiftBox} preload="none" alt='Gift' className='h-[40px] w-[40px]' autoPlay loop muted />
            <div>
              <div className='text-black font-semibold text-[10px] xl:text-sm '>
                Refer & Earn
              </div>
              <div className='bg-primary text-white text-[8px] px-2 xl:text-[10px] '>
                Get Upto ₹5000*
              </div>
            </div>
          </div>

          {/* Vertical Line */}
        </div>

        <div className='h-[40px] w-[2px] bg-primary ' />

        <div className='flex items-center justify-center'>
          <div
            className='relative'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className='flex items-center  space-x-2 cursor-pointer'>
              <Dots className='h-[17px] w-[17px] xl:w-[30px] xl:h-[30px]' />
              <span className='text-primaryText text-[10px] xl:text-xs '>
                More
              </span>
            </div>
            {dotsDropdownVisible && (
              <MoreDropdown onClose={() => setDotsDropdownVisible(false)} />
            )}
          </div>

          {/* Vertical Line */}
        </div>

        <div className='h-[40px] w-[2px] bg-primary' />

        <div className='flex items-center space-x-4 '>
          <Notification className='h-[35px] w-[35px]' />

          {token === null ? (
            <button
              onClick={openModal}
              className='bg-primary flex justify-center items-center p-[10px] w-[90px] h-[30px] xl:w-[150px] xl:h-[35px] text-white rounded-full shadow-md text-[8px] xl:text-[12px] '
            >
              SIGN UP / LOGIN
            </button>
          ) : (
            <div
              onMouseEnter={handleMouseEnterProfile}
              onMouseLeave={handleMouseLeaveProfile}
              className='flex relative items-center w-[120px]  xl:min-w-[150px] xl:h-[35px] justify-center space-x-2 bg-white border border-primary rounded-full px-4 py-2 shadow-md cursor-pointer'
            >
              <User className='h-5 w-5' />
              <span className='text-primaryText text-xs'>
                Hi {name?.split(" ")[0]}
              </span>
              {profileDropdownVisible && <ProfileDropDown onClose={onclose} />}
            </div>
          )}
        </div>
      </div>

      {/* Mobile Navbar */}
      <div className='py-6 px-4 w-full justify-between lg:hidden flex'>
        <div className='flex items-center'>
          <div onClick={() => setMobileMenuOpen(true)}>
            <Menu className='h-[40px] ml-[-10px]' />
          </div>
          <div onClick={() => navigate("/")}>
            <Logo className='h-[30px] ml-[-30px]' />
          </div>
        </div>

        <div className='flex items-center space-x-4'>
          <div onClick={() => setMobileSearch(true)}>
            <Green_Search className='w-[30px] h-[30px]' />
          </div>
          <Notification className='h-[35px] w-[35px]' />
          <div onClick={openModal}>
            <User className='h-[35px] w-[35px]' />
          </div>
        </div>
      </div>

      {/* Courses Section */}
      <div
        className='hidden lg:flex items-center px-4 space-x-4  w-full bg-transparent'
        style={{
          position: "absolute",
          top: "100px",
          left: "0",
          right: "0",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 2,
        }}
      >
        <div
          onClick={() => setSidebarOpen(true)} // Open sidebar
          className='flex flex-col space-x-2 cursor-pointer items-center text-white text-xs group'
        >
          {/* Row containing the Courses icon and text */}
          <div className='flex flex-row'>
            <Courses className='h-[15px] w-[15px] mr-2' />
            <span className='group-hover:text-primaryText'>All Programs</span>
          </div>

          {/* Line that becomes visible on hover */}
          <div className='h-[3px] w-[92px] ml-12 hidden relative top-[10px] left-0 bg-primary md:h-[2px] group-hover:flex' />
        </div>

        <div className='flex space-x-6 p-3 ml-[10px]'>
          {dummyPrograms.map((program) => (
            <div
              className='relative flex items-center space-x-2 cursor-pointer'
              key={program.id}
              onClick={() => handleNavigation(program.program_master_name)}
            >
              <div className='text-white text-xs hover:text-primaryText'>
                {program.program_master_name}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modals */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        className={"modal"}
        contentLabel='Authentication Modal'
      >
        <Authentication onClose={closeModal} />
      </Modal>

      <ReferEarnModal
        isOpen={isReferEarnModalOpen}
        onRequestClose={closeReferEarnModal}
      />

      {isMobileMenuOpen && (
        <MobileMenu
          handleNavigation={handleNavigation}
          dummyPrograms={dummyPrograms}
          setMobileMenuOpen={setMobileMenuOpen}
          setReferEarnModalOpen={setReferEarnModalOpen}
        />
      )}
      {isMobileSearch && (
        <MobileSearch
          handleSearchChange={handleSearchChange}
          handleSearchSelect={handleSearchSelect}
          handleSearchSubmit={handleSearchSubmit}
          setMobileSearch={setMobileSearch}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          isLoading={isLoading}
          universityName={universityName}
          setUniversityName={setUniversityName}
          saveToRecentSearches={saveToRecentSearches}
          recentSearches={recentSearches}
          debouncedFetchUniversities={debouncedFetchUniversities}
          removeFromRecentSearches={removeFromRecentSearches}
        />
      )}

      {/* Sidebar */}
      {isSidebarOpen && (
        <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex'>
          <div className='bg-white w-[300px] h-full shadow-lg'>
            {/* Header */}
            <div className='flex items-center justify-between p-4 border-b border-gray-200'>
              <h2 className='text-lg font-bold'>All Programs</h2>
              <button
                onClick={() => setSidebarOpen(false)} // Close sidebar
                className='text-gray-500 hover:text-gray-700'
              >
                ✕
              </button>
            </div>

            {/* Search Box */}
            <div className='p-4'>
              <input
                type='text'
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                placeholder='Search All Programs'
                className='w-full px-4 py-2 border border-gray-300 rounded-md'
              />
            </div>

            {/* Degrees List */}
            <div className='p-4 overflow-y-auto h-full '>
              {filteredDegrees.length > 0 ? (
                <ul>
                  {filteredDegrees.map((degree) => (
                    <li
                      key={degree.id}
                      onClick={() =>
                        handleNavigation(degree.program_master_name)
                      }
                      className='flex justify-between items-center py-2 px-4 hover:bg-gray-200 rounded-md cursor-pointer'
                    >
                      {degree.program_master_name}
                      <span className='text-gray-500'>›</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className='text-gray-500 text-sm'>No courses found</p>
              )}
            </div>
          </div>

          {/* Close Sidebar on Click Outside */}
          <div className='flex-1' onClick={() => setSidebarOpen(false)}></div>
        </div>
      )}

      {/* Overlay */}
      {isOverlayOpen && (
        <div className='fixed inset-0 z-50 bg-black bg-opacity-50 flex'>
          <div
            className='fixed top-0 left-0 right-0 bg-white z-50'
            style={{
              backdropFilter: "blur(10px)",
            }}
          >
            <div className='flex justify-between items-center'>
              <div className='flex w-full justify-evenly p-2'>
                <input
                  type='text'
                  value={universityName}
                  onChange={handleSearchChange}
                  onKeyDown={handleSearchSubmit}
                  style={{ border: "none", width: "90%", fontSize: "16px" }}
                  className='w-full px-4 py-2 border border-gray-300 font-bold text-gray-600'
                  placeholder='Search for Colleges, Exams, News and more'
                  autoFocus
                />
                <button
                  onClick={closeOverlay}
                  className='ml-4 text-gray-500 text-xl font-bold'
                >
                  ✕
                </button>
              </div>
            </div>

            <div className='w-full mx-auto px-4 py-3 bg-gray-100'>
              {/* Top Section: Search Bar */}

              {universityName.length < MIN_LENGTH &&
              recentSearches.length > 0 ? (
                <div className='mt-2 px-8'>
                  <h3 className='text-primaryText font-semibold mb-2'>
                    RECENT SEARCHES
                  </h3>
                  <ul className='text-gray-700 space-y-2'>
                    {recentSearches.map((term, index) => (
                      <li
                        key={index}
                        className='flex justify-between items-center cursor-pointer'
                      >
                        <span
                          className='font-bold text-gray-600 text-md'
                          onClick={() => {
                            setUniversityName(term);
                            debouncedFetchUniversities(term);
                          }}
                        >
                          • {term}
                        </span>
                        <button
                          className='text-red-500 hover:text-red-700 text-sm ml-4'
                          onClick={() => removeFromRecentSearches(term)}
                        >
                          ✕
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : universityName.length < MIN_LENGTH ? (
                <div className='mt-2 px-8'>
                  <h3 className='text-primaryText font-semibold mb-2'>
                    TRENDING SEARCHES
                  </h3>
                  <ul className='text-gray-700 space-y-2'>
                    <li>• Upcoming Exams</li>
                    <li>• "IIT" in Colleges</li>
                    <li>• "CAT" in Exams</li>
                    <li>• "CAT Cutoff" in News</li>
                    <li>• "MBA Colleges" in Delhi/NCR</li>
                    <li>• "MCA Colleges" in Delhi/NCR</li>
                  </ul>
                </div>
              ) : null}

              {/* Search Results */}
              {universityName.length >= MIN_LENGTH && (
                <div
                  className='m min-h-full overflow-y-auto border-t pt-2'
                  style={{ borderTop: "1px solid #eee" }}
                >
                  {isLoading ? (
                    skeletonLoader()
                  ) : searchResults.length > 0 ? (
                    <ul className='space-y-2'>
                      {searchResults.map((result) => (
                        <li
                          key={result.university_id}
                          className='py-2 px-4 bg-gray-100 rounded-md hover:bg-gray-200 cursor-pointer flex items-center space-x-4 '
                          onClick={() => handleSearchSelect(result)}
                        >
                          {result.logo && (
                            <img
                              src={`${Img_URL}/${result.logo}`} // Use the logo URL from the result
                              alt={`${result.university_name} logo`}
                              className='h-[50px] w-[50px]  ' // Adjust size as needed
                            />
                          )}
                          <span className='font-bold text-gray-600 text-md'>
                            {result.university_name}
                          </span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className='text-gray-500 mt-4'>No results found</p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
