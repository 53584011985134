import React, { useState } from "react";
import { FiUpload } from "react-icons/fi";

function DigitalPartner() {
  const [toggle, setToggle] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState({});

  const handleFileUpload = () => {
    document.getElementById("fileInput").click();
  };

  const handleDropdownChange = (key, value) => {
    setSelectedDropdown((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="bg-white font-sans-serif min-h-screen flex flex-col items-center px-4 md:px-8 lg:px-16">
      {/* Top Text */}
      <h1 className="text-center text-[28px]  md:text-[30px] lg:text-[40px] font-bold leading-[1.2] mt-8 md:mt-12 lg:mt-[70px] w-[100%] lg:max-w-[684px]">
      Partner with Us!
      </h1>

      {/* Subheading */}
      <p className="text-center text-[16px] md:text-[20px] lg:text-[24px] font-medium leading-[1.3] mt-6 md:mt-8 lg:mt-[50px] max-w-[90%] lg:max-w-[1100px]">
      Together, we can innovate, grow, and achieve remarkable milestones.
      </p>

      {/* Main Form Container */}
      <div
        className="bg-white shadow-xl border md:mb-12 rounded-lg p-6 md:p-8 lg:p-12 mt-6 md:mt-8  lg:mb-[130px] lg:mt-[130px] w-full max-w-[900px] mx-auto"
        style={{ boxShadow: "1px 2px 11px 4px #00000040" }}
      >
        {/* Form Heading */}
        <h2 className="text-center text-[18px] md:text-[20px] lg:text-[22px] font-bold leading-[1.3] mb-6 md:mb-8">
          Provide the Required Details
        </h2>

        {/* Form */}
        <form className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
          {/* Column 1 */}
          <div className="flex flex-col gap-6">
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Full Name</label>
              <input
                type="text"
                className="w-full h-[40px] p-3 border rounded-lg"
                placeholder="Enter your full name"
                style={{ borderColor: "#00000080", borderWidth: "1px" }}
              />
            </div>
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Email Address</label>
              <input
                type="email"
                className="w-full h-[40px] p-3 border rounded-lg"
                placeholder="Enter your email"
                style={{ borderColor: "#00000080", borderWidth: "1px" }}
              />
            </div>
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Contact Number</label>
              <input
                type="tel"
                className="w-full h-[40px] p-3 border rounded-lg"
                placeholder="Enter your contact number"
                style={{ borderColor: "#00000080", borderWidth: "1px" }}
              />
            </div>
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Preferred Communication Mode</label>
              <select
                className="w-full h-[40px] p-2 border rounded-lg"
                value={selectedDropdown.communication || ""}
                onChange={(e) => handleDropdownChange("communication", e.target.value)}
                style={{ borderColor: "#00000080", borderWidth: "1px" }}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
                <option value="message">Message</option>
              </select>
            </div>
          </div>

          {/* Column 2 */}
          <div className="flex flex-col gap-6">
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Region / City</label>
              <select
                className="w-full h-[40px] p-2 border rounded-lg"
                value={selectedDropdown.region || ""}
                onChange={(e) => handleDropdownChange("region", e.target.value)}
                style={{ borderColor: "#00000080", borderWidth: "1px" }}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="region1">Region 1</option>
                <option value="region2">Region 2</option>
                <option value="region3">Region 3</option>
              </select>
            </div>
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Previous Experience</label>
              <input
                type="text"
                className="w-full h-[40px] p-3 border rounded-lg"
                placeholder="Enter your experience"
                style={{ borderColor: "#00000080", borderWidth: "1px" }}
              />
            </div>
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Aadhaar Card</label>
              <div className="relative">
                <input type="file" id="fileInput" className="hidden" />
                <button
                  type="button"
                  onClick={handleFileUpload}
                  className="w-full h-[40px] p-3 border rounded-lg flex items-center justify-between text-gray-500"
                  style={{ borderColor: "#00000080", borderWidth: "1px" }}
                >
                  Upload Aadhaar
                  <FiUpload className="text-gray-500" />
                </button>
              </div>
            </div>
            <div>
              <label className="block mb-2 text-[16px] md:text-[18px] font-medium">Account Details</label>
              <select
                className="w-full h-[40px] p-2 border rounded-lg"
                value={selectedDropdown.account || ""}
                onChange={(e) => handleDropdownChange("account", e.target.value)}
                style={{ borderColor: "#00000080", borderWidth: "1px" }}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="bank1">Bank 1</option>
                <option value="bank2">Bank 2</option>
                <option value="bank3">Bank 3</option>
              </select>
            </div>
          </div>
        </form>

        {/* Toggle Button Section */}
        <div className="mt-8 flex flex-col items-center gap-4">
          <div className="flex items-center gap-4">
            <button
              className={`relative w-[50px] h-[24px] rounded-full flex items-center px-1 transition-colors ${
                toggle ? "bg-[#3ACA2E]" : "bg-gray-300"
              }`}
              onClick={(e) => {
                e.preventDefault();
                setToggle(!toggle);
              }}
            >
              <span
                className={`w-[20px] h-[20px] bg-white rounded-full transition-transform transform ${
                  toggle ? "translate-x-6" : "translate-x-0"
                }`}
              ></span>
            </button>
            <span className="text-[14px] md:text-[16px] font-medium">
              By Proceeding ahead you expressly agree to CollegeDwarka{" "}
              <span className="text-[#3ACA2E] cursor-pointer">Terms & Conditions</span> and{" "}
              <span className="text-[#3ACA2E] cursor-pointer">Privacy Policy</span>.
            </span>
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            className="bg-[#3ACA2E] text-white font-medium w-[300px] h-[40px] rounded-lg mt-6"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default DigitalPartner;
