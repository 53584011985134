import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as Logo } from "../../src/assets/landing/Logo_Green.svg";
import popupImg from "../../src/assets/landing/popup.webp";
import { Base_URL } from "../apiConfig";

const PopupContent = ({ onSubmit }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobilenumber: "",
    description: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const clearFields = () => {
    setFormData({
      name: "",
      mobilenumber: "",
      description: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${Base_URL}/create/counselling`);

      toast.success("Details submitted successfully!");
      onSubmit(); // Close the popup and persist submission
      clearFields(); // Clear all input fields

      setLoading(false);
    } catch (err) {
      console.error("Error:", err);
      toast.error("Failed to submit details.Try again later.");
      setLoading(false);
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className='max-w-[65vw] mx-auto'>
      <div className='grid grid-cols-1 md:grid-cols-[45%_55%] '>
        {/* Left Section */}
        <div className='flex flex-col'>
          {/* Image */}
          <img
            src={popupImg}
            loading="lazy"
            alt='Counselling Illustration'
            className='w-full object-cover'
          />

          {/* Description Box */}
          <div className='bg-cmsgreen text-white p-4'>
            {/* <h3 className="text-sm md:text-[18px] font-bold mt-3 text-center md:text-left">
              Get Free Counselling from us
            </h3> */}
            <div className='bg-cmsgreen text-white p-3 sm:p-4 flex  items-center'>
              <h3 className='text-sm md:text-[18px] font-bold  text-center'>
                Get Free Counselling from us
              </h3>
            </div>
            <ul className='mt-3 list-disc text-left pl-5 p-3 hidden md:block'>
              <li className='mb-2'>
                Our platform offers personalized counselling to guide you in
                making the best educational choices.
              </li>
              <li className='mb-2'>
                Join us to explore your options and make informed decisions!
              </li>
              <li className='mb-2'>
                Receive one-on-one sessions with experienced education
                counsellors.
              </li>
              <li className='mb-2'>
                Get tailored recommendations based on your unique academic and
                career goals.
              </li>
            </ul>
          </div>
        </div>

        {/* Right Section - Form */}
        <div className='flex flex-col items-center '>
          <div className='flex justify-center'>
            <Logo className='w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-36 lg:h-36 hidden md:block' />
          </div>

          <form
            onSubmit={handleSubmit}
            className='space-y-4 lg:space-y-6 w-full max-w-[90%] md:max-w-[80%] py-4 md:py-0'
          >
            <input
              type='text'
              placeholder='Name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              required
              className='w-full  sm:p-3 border border-gray-300 rounded-lg text-xs sm:text-sm md:text-[16px]
              focus:outline-none focus:ring-2 focus:ring-cmsgreen focus:border-cmsgreen'
            />
            <input
              type='tel'
              placeholder='Mobile Number'
              name='mobilenumber'
              value={formData.mobilenumber}
              onChange={handleChange}
              required
              className='w-full  sm:p-3 border border-gray-300 rounded-lg text-xs sm:text-sm md:text-[16px]
             focus:outline-none focus:ring-2 focus:ring-cmsgreen focus:border-cmsgreen'
            />
            <textarea
              placeholder='Write your Queries here'
              name='description'
              value={formData.description}
              onChange={handleChange}
              rows='3'
              className='w-full  sm:p-3 border border-gray-300 rounded-lg text-xs sm:text-sm md:text-[16px]
              focus:outline-none focus:ring-2 focus:ring-cmsgreen focus:border-cmsgreen'
            ></textarea>

            {/* Terms and Conditions with checkbox */}
            {/* <div className="flex flex-col items-center text-center mt-4">
              <p className="text-xs md:text-sm text-textGray">
                By proceeding ahead, you expressly agree to CollegeDwarka's{' '}
                <a href="/terms-and-conditions" className="text-cmsgreen">
                  Terms & Conditions
                </a>{' '}
                and{' '}
                <a href="/privacy-policy" className="text-cmsgreen">
                  Privacy Policy
                </a>
                .
              </p>
            </div> */}
            <div className='flex flex-col mt-4'>
              <div className='flex space-x-2'>
                {/* Checkbox */}
                <input
                  type='checkbox'
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  className=' mt-1 w-4 h-4 text-cmsgreen border-gray-300 rounded'
                />

                {/* Terms and Conditions Text */}
                <div className='flex flex-col items-center text-left'>
                  <p className='text-xs md:text-sm '>
                    By proceeding ahead, you expressly agree to CollegeDwarka's{" "}
                    <a href='/terms-and-conditions' className='text-cmsgreen'>
                      Terms & Conditions
                    </a>{" "}
                    and{" "}
                    <a href='/privacy-policy' className='text-cmsgreen'>
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>

            <button
              type='submit'
              className='w-full px-6  py-3 bg-cmsgreen text-white text-sm font-semibold rounded-lg'
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PopupContent;
